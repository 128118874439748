<template>
    <div class="tab-note">
        <div v-if="list.length > 0" class="content">
            <div class="card-note" v-for="note, index in localList" :key="index">
                <div class="card-note-header">
                    <span class="header-date">{{ formatDate(note.event_time) }}</span>

                    <div class="btn-delete" @click="removeNote(note.real_id)" v-if="hasDeleteButton(index, localList)">
                        <icon-delete />
                    </div>

                    <div class="btn-open" @click="note.open = !note.open" :class="{ 'ml-auto': !hasDeleteButton(index, localList)}">
                        <dropdown-icon :class="{ 'open': note.open }" />
                    </div>
                </div>

                <div class="separator"></div>

                <div class="div-info">
                    <div class="div-row striped">
                        <span class="prop-name">ID</span>
                        <span class="prop-value">{{ note.real_id }} </span>
                    </div>

                    <div class="div-row">
                        <span class="prop-name">{{ $t('ProductionList.User') }}</span>
                        <div class="div-avatar">
                            <b-avatar v-if="note.photo" class="user-photo" :size="avatarSize" variant="light-primary"
                                :src="note.photo" />

                            <b-avatar v-else variant="light-secondary" :size="avatarSize">
                                <span>{{ getInitials(note.user) }}</span>
                            </b-avatar>


                            <span class="prop-value ellipsis-note">{{ note.user }} </span>
                        </div>
                    </div>

                    <div class="div-row striped">
                        <span class="prop-name">Apontamento</span>

                        <div class="order-status">
                            <span class="ellipsis-note">
                                {{ note.is_rework === "0" ? "Produtivo" : "Retrabalho" }}
                            </span>
                        </div>
                    </div>

                    <div class="div-row striped">
                        <span class="prop-name">Status</span>

                        <div class="order-status" :style="getBackgroundColorLegend(note.color)">
                            <span class="ellipsis-note">
                                {{ note.status }}
                            </span>
                        </div>
                    </div>

                    <b-collapse v-model="note.open" class="collapse-info">
                        <div class="div-row">
                            <span class="prop-name">{{ $t('ordersList.Resource') }}</span>
                            <span class="prop-value ellipsis-info" v-b-tooltip.hover="{customClass: 'info-order-tooltip'}" :title="note.resource">{{ note.resource }} </span>
                        </div>

                        <div class="div-row striped" v-if="!isControlByHour">
                            <span class="prop-name">{{ $t('OperationProgress.Produced') }}</span>
                            <span class="prop-value">
                                {{ note.produced_quantity ? parseInt(note.produced_quantity) : "" }}
                            </span>
                        </div>

                        <div class="div-row" v-if="!isControlByHour">
                            <span class="prop-name">{{ $t('ProductionProgress.AppointmentModal.Card.Rework') }}</span>
                            <span class="prop-value">
                                {{ note.rework_quantity ? parseInt(note.rework_quantity) : "" }}
                            </span>
                        </div>

                        <div class="div-row striped" v-if="!isControlByHour">
                            <span class="prop-name">{{ $t('Scrap') }}</span>
                            <span class="prop-value">
                                {{ note.rework_quantity ? parseInt(note.refuse_quantity) : "" }}
                            </span>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>

        <NotFound v-else />
    </div>
</template>

<script>
import { BTooltip, BCollapse, BAvatar, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapMutations } from 'vuex';
import moment from "moment";

export default {
    components: {
        BTooltip,
        NotFound: () => import('../NotFound.vue'),
        IconDelete: () => import('@/assets/images/pages/delete-trash-icon.svg'),
        DropdownIcon: () => import('@/assets/images/icons/drop-icon.svg'),
        moment,
        BCollapse,
        BAvatar,
        VBTooltip
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        isMultipleOrders: {
            type: Boolean,
            default: false,
        },
        isControlByHour: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        list(value) {
            this.localList = value;
        }
    },
    mounted() {
        this.localList = this.list;
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    data() {
        return {
            localList: [],
            language: this.$cookies.get('userInfo').language,
            userData: JSON.parse(localStorage.getItem('userData')),
            avatarSize: 40,
            currentSite: JSON.parse(localStorage.getItem("currentSite")),
        }
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        ...mapActions('app', [
            'removeRequestLoading'
        ]),
        hasDeleteButton(index, localList) {
            const user_id = JSON.parse(localStorage.getItem('userData')).guid;
            return (index == 0 && !this.isMultipleOrders && !this.isControlByHour) || (index === localList.findIndex(appointment => (appointment.user_id === user_id)) && !this.isMultipleOrders && this.isControlByHour)
        },
        formatDate(date) {
            if (this.language == 'pt-BR')
                return moment(date).format('DD-MM-YYYY HH:mm');
            else
                return moment(date).format('MM-DD-YYYY HH:mm');
        },
        getBackgroundColorLegend(color) {
            if (color) return `color:${color}; margin-right:3px; background-color:${color}20`;

            return `background-color:secondary`;
        },
        removeNote(real_id) {
            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('Operator.RemoveNoteAlert'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('ResourceStatus.Delete'),
                customClass: {
                    container: 'swal-default',
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);

                    this.$http.delete(`/api/${this.$router.currentRoute.params.prefix}/operation-progress/${real_id}?preactor_order_id=0&site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}`)
                        .then(() => {
                            this.$emit('deleteOperationProgress');
                        })
                        .catch((error) => {
                            this.showToast(
                                "Error!",
                                "FrownIcon",
                                error.response.data.message,
                                "danger"
                            );

                            this.removeRequestLoading();
                        });
                }
            })
        },
        getInitials(fullName) {
            const nameParts = fullName.split(" ");
            const firstInitial = nameParts[0][0];
            const lastInitial = nameParts[nameParts.length - 1][0];

            return firstInitial + lastInitial;
        },
        handleResize() {
            const windowWidth = window.innerWidth;

            if (windowWidth <= 425)
                this.avatarSize = 24;
            else
                this.avatarSize = 40;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
.info-order-tooltip {
    margin: 0;
    z-index: 99999999;
    max-height: none !important;

    .tooltip-inner {
        max-width: 516px !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: left;

        padding: 8px;
        background-color: #362F2B;
        border-radius: 6px;
        border: none;
        outline: none;
    }

    .arrow {
        &::before {
            border-top-color: #362F2B;
            border-bottom-color: #362F2B;
        }
    }
}

.tab-note {
    flex-grow: 1;

    .ellipsis-note {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .card-note {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 16px;
        min-height: 295px;
        background: #FFFFFF;
        border: 1px solid #CFC4BE;
        border-radius: 6px;

        .card-note-header {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;

            .header-date {
                font-weight: 600;
                font-size: 26px;
                line-height: 36px;
                color: #4C4541;
            }

            .btn-delete {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: 38px;
                height: 38px;
                border: 1px solid #D32F2F;
                border-radius: 5px;
                cursor: pointer;
                margin-left: auto;

                &:hover {
                    background-color: #ffdbc4;
                }
            }

            .btn-open {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: 38px;
                height: 38px;
                border: 1px solid #974900;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #FFEDE2;
                }

                svg {
                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .separator {
            height: 1px;
            width: 100%;
            background: #CFC4BE;
        }

        .order-status {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1px 9px;
            height: 28px;
            border-radius: 4px;
            overflow: hidden;

            span {
                font-weight: 600;
                font-size: 16px;
            }
        }

        .div-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .div-row {
                height: 60px;
                display: flex;
                padding: 16px;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 20px;
                border-radius: 6px;
            }

            .striped {
                background-color: #FAFAFA;
            }
        }

        .collapse-info {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .prop-name {
            font-weight: 400;
            font-size: 16px;
            color: #998F8A;
        }

        .prop-value {
            font-weight: 600;
            font-size: 16px;
            color: #4C4541;
        }

        .div-avatar {
            display: flex;
            align-items: center;
            gap: 7.45px;
            overflow: hidden;
        }

        .ellipsis-info {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }
    }
}

@media (max-width: 768px) {}

@media (max-width: 425px) {
    .tab-note {
        .card-note {
            .card-note-header {
                .header-date {
                    font-size: 18px;
                }

                .btn-delete {
                    width: 28px;
                    height: 28px;
                    padding: 0px;
                }

                .btn-open {
                    width: 28px;
                    height: 28px;
                    padding: 0px;
                }
            }

            .prop-name {
                font-size: 12px;
            }

            .prop-value {
                font-size: 14px;
            }

            .order-status {
                height: 16px;

                span {
                    font-size: 12px !important;
                    line-height: 16px !important;
                }
            }
        }
    }
}

@media (max-width: 325px) {
    .tab-note {
        .card-note {
            .card-note-header {
                gap: 8px
            }
        }
    }
}
</style>
